import './Groups.css';

import React from 'react';
import { useSelector } from 'react-redux';

import { RootState, User, Group } from '../app/utilities/types';

import { selectAllPlayers } from '../store/users.slice'
import { selectGroupMembers } from '../store/groups.slice'

import Users from '../components/Users';

interface GroupsProps {
  showAll?: boolean,
  groupId?: string,
  userId?: string,
  isAdmin?: boolean,
}

const Groups: React.FC<GroupsProps> = ({showAll = true, groupId, userId, isAdmin}) => {
  const {users} = useSelector((state: RootState) => state.usersState);

  // TODO TEMP
  // useEffect(() => {
  //   console.log(`LOGIN useEffect: users=${JSON.stringify(users)}, users.length=${JSON.stringify(users.length)}`);
  // }, [users]);

  const {groups} = useSelector((state: RootState) => state.groupsState);

  // Add green dot for online users
/*
  const usersWithOnlineData = useMemo(() => {
    if (users.length < 1) {
      return [];
    }

    return users
      .map((user) => ({
        ...user,
        online: onlineUsersById.some((onlineId) => onlineId === user.id),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, onlineUsersById]);
*/

  //! const activeUsers = usersWithOnlineData.filter(user => user.online);
  //! if (!activeUsers.length) return <div>No active users! users={JSON.stringify(users)} activeUsers={JSON.stringify(activeUsers)}</div>;

  const allPlayers = selectAllPlayers(users);

  // TODO THIS COULD ALSO BE SHARED BY THE EntitiesInfo COMPONENT
  const groupsMembers = selectGroupMembers(
    allPlayers,
    groups,
    groupId,
    userId,
    showAll,
  );

  // TODO IF GROUP HAS NO MEMBER IT WILL NOT APPEAR IN THIS LIST (AND SO ITS IMAGE WILL NOT BE DISPLAYED!)
  //! console.log(`groupsMembers=${JSON.stringify(groupsMembers)}`);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // alignItems: "center",
        flexWrap: "wrap",
      }}>
      {groupsMembers.map(([group, members]) => (
        <div
          key={group.id}
          style={{
            display: "flex",
            // flexDirection: "row",
            flexDirection: "column",
            justifyContent: group.id ? "flex-start" : "center",
            alignItems: "center",
            flex: 1,
            // maxWidth: 600,
            minWidth: 300,
            border: "4px dotted #aaa",
            borderRadius: 20,
            margin: 5,
            padding: 5,
        }}>

          {/* <div style={{display: "flex", justifyContent: "center", width: "100%", height: "max-content", flex: "1 1"}}> */}
{(group.id || groupsMembers.length > 1) && <>
          <div
            style={{
display: "flex",
justifyContent: "center",
alignItems: "center",
              flex: 1,
              width: "100%",
              height: "auto",
              minWidth: 100,
              minHeight: 100,
              maxWidth: isAdmin ? 220 : 220,
              // maxHeight: isAdmin ? 250 : 300,
              maxHeight: isAdmin ? 250 : 300,
              // border: "1px dashed green",
              margin: 5,
            }}>
            {group.id &&
              <img
                src={group.image ?? "/assets/images/groups/default.jpg"}
                alt={`Groupe ${group.name}`}
                className="bounce-in"
                style={{
//flex: 0,
                  // margin: 5,
                  borderRadius: 16,
                  // minWidth: 100,
                  // minHeight: 100,
                  // maxWidth: 250,
                  // width: 250,
                  // height: 250,
                  width: "100%",
                  height: "100%",
                  // flex: "0 1",
                  // border: "1px dashed red",
                }}
              />
            }
            {!group.id && groupsMembers.length > 1 && <>
              <div style={{textTransform: "uppercase", fontWeight: "bold", fontSize: "1.5rem", margin: 10}}>Individus</div>
            </>}
          </div>

          {group.id &&
            <div style={{fontWeight: "bold", fontSize: "1.6rem", margin: 10}}>
              {group.id ? group.name : "Individus"}
            </div>}
</>}
{/* <div style={{flex: "1 1 0%"}}> */}
          <Users users={members} focusUserId={userId} />
{/* </div> */}
        </div>
      ))}
    </div>
  );
}

export default Groups;
