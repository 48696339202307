//! TEMP SO BUILD/DEPLOY WON'T FAIL
/* eslint-disable */


import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Particles from '../app/utilities/particles';
import { VoteIcon, inlineSvg, getIcon } from './VoteIcons';
import UserAvatar from './UserAvatar';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { calcCombinedPoints } from '../app/utilities/voteCombiner';
import { selectAllPlayers } from '../store/users.slice'
import { selectRepresentativePlayers } from '../store/groups.slice'

import {
  RootState,
  User,
  Group,
  Quiz,
  Question
} from '../app/utilities/types';

import './Votometer.css';

interface Props {
  quiz: Quiz,
  users: User[],
  groups: Group[],
  votee?: User,
}

export const Votometer: React.FC<Props> = ({
  quiz,
  users,
  groups,
  votee,
}) => {
  const { voteeId } = useSelector(
    (state: RootState) => state.mainState
  );
  const { voteHistory } = useSelector(
    (state: RootState) => state.quizState
  );

  const allPlayers = selectAllPlayers(users);

  const representativePlayers = selectRepresentativePlayers(
    allPlayers,
    groups,
    // groupId,
    // userId,
    // showAll,
  );

//! COPIED AND ADAPTED FROM Votes.tsx COMPONENT

const countVotes = (question: Question, questionIndex: number, _voteeId?: string): any => {
  if (_voteeId !== voteeId) {
    // If not votee user who's currently receiving votes then use vote history
    const voteeHistory = voteHistory.find(history => history.voteeId === _voteeId);
    const quizzesHistory = voteeHistory?.quizzesHistory;

    //? SHOULDN'T BE NECESSARY
    if (!quizzesHistory) {
      const totalVotes = 0;
      const numVotes = 0;
      const maxVotes = 0;
      return {totalVotes, numVotes, maxVotes};
    }

    const quizIndex = quizzesHistory.length - 1; // TODO CHECK IF THIS IS OK FOR THOSE WHO HAVEN'T BEEN VOTED FOR YET
    /*
    question = {
      answers: quizzesHistory[quizIndex].questionsHistory[questionIndex].answers,
      // Plugin from current 'live' question structure as needed below
      maxVotes: question.maxVotes,
    } as Question;
    */
    const questionHistory = quizzesHistory[quizIndex].questionsHistory[questionIndex];
    const totalVotes = questionHistory.totalVotes;
    const numVotes = questionHistory.numVotes;
    const maxVotes = questionHistory.maxVotes;
    return {totalVotes, numVotes, maxVotes};
  }

  // Check if user has abstained from voting
/*
  if (question.answers.some(answer => answer.userId === userId && answer.abstained)) {
//!        return null;
return {totalVotes: 0, numVotes : 0};
  }
*/

//COUNT MAX VOTES
let maxVotes = 0;

const numVoters = representativePlayers
  .filter(user => user.id !== _voteeId)
  .reduce((numVoters, user) => {
    const abstained = question.answers.some(answer => answer.userId === user.id && answer.abstained);
    return numVoters + (abstained ? 0 : 1);
  }, 0);

maxVotes += question.maxVotes * numVoters;
console.log(`maxVotes=${maxVotes}`);
//console.log(`Filtered question.answers for votee ${_voteeId}`,question.answers);

// .filter(answer => answer.userId === userId && !answer.abstained));

//! TAKEN FROM SEE Votes.tsx (showAverageVotes)
  const combinedPoints = calcCombinedPoints(question.answers, users, groups);
  let numVotes = 0;
  const totalVotes = combinedPoints.totalPoints;
  const totalPoints = combinedPoints.totalPoints;
  const numAnswers = combinedPoints.numAnswers;
  if (numAnswers) {
    numVotes = totalPoints / numAnswers;
  }
/*
  const {total: totalVotes, num: numVotes} = question.answers
    //? .filter(answer => answer.userId !== _voteeId)
    .filter(answer => !answer.abstained)
    .reduce((votes, answer) =>
      ({total: votes.total + (answer.choice ?? 0), num: votes.num + 1}), {total: 0, num: 0});
*/
//console.log('totalVotes',totalVotes,'numVotes',numVotes);
  return {totalVotes, numVotes, maxVotes};
};

  //! START
function calcTotalVotes(_voteeId?: string) {


let totalVotes = 0;
let maxTotalVotes = 0;
// TODO THE PROBLEM HERE IS THAT THE ANSWERS FOR THESE QUESTIONS ARE THOSE FOR THE CURRENTLY ACTIVE VOTEE!!!
// TODO SO ALL PLAYERS SEE THE SAME COUNTS (OF THIS ACTIVE VOTEE)
// TODO USE HISTORY OF QUESTIONS/ANSWERS (SEND TO CLIENT IF ADMIN?) AND USE THE REQD voteeId QUESTIONS BASED ON THIS
quiz.questions.forEach((question, i) => {
  const {totalVotes: totalVotesForQuestion, maxVotes: maxVotesForQuestion} = countVotes(question, i, _voteeId);
//  console.log('totalVotesForQuestion',totalVotesForQuestion,'numVotesForQuestion',numVotesForQuestion);
  totalVotes += totalVotesForQuestion;
  maxTotalVotes += maxVotesForQuestion;
});
console.log('totalVotes', totalVotes, 'maxTotalVotes', maxTotalVotes);


//! END
return {totalVotes, maxTotalVotes};
}

function calcVotometerHeight(userId: string) {
  const {totalVotes, maxTotalVotes} = calcTotalVotes(userId);

  const votometerBarHeightFraction = maxTotalVotes ? (totalVotes / maxTotalVotes) : 0;

  return {
    totalVotes,
    maxTotalVotes,
    votometerBarHeightFraction,
  }
}

  // if (totalVotes === maxTotalVotes) {
  //   Particles.confetti(document.body);
  // }

  console.log(`url('data:image/svg+xml;charset=UTF-8, ${inlineSvg({iconType: "heart", colour: 'red', size: 16})}');`);

  return (
<div style={{
  display: "flex",
  flexWrap: "wrap",
  gap: "15px 0",
  height: "calc(100vh - 300px)", // TODO REPLACE HARDCODED VALUES WITH CONSTANTS (HERE, PAGE HEADER HEIGHT)
  justifyContent: "space-around",
  alignItems: "flex-end",
}}>
    {/* <img src="/assets/images/votometer.png" alt="Votometer" /> */}
{representativePlayers.map((user, i) => {
  // Ensure first member of a group only is the representative votee who will receive the votes
  let group: Group|undefined;
  if (user.groupId) {
    group = groups.find(group => group.id === user.groupId);
  }

  const {
    totalVotes,
    maxTotalVotes,
    votometerBarHeightFraction,
  } = calcVotometerHeight(user.id);

  const percentageVotes = votometerBarHeightFraction * 100;

  return (<div style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px 0",
    minWidth: 200,
    height: "100%",
    justifyContent: "flex-end",
  }}>
      <img
        src="/assets/images/down-arrow.png"
        className={`votee-pointer ${user.id !== votee?.id ? 'hidden' : ''}`}
      />

      {/* <EntityInfo key={user.id} user={user} {...props} /> */}
      {/* {user.groupId &&
              <img
                src={group.image ?? "/assets/images/groups/default.jpg"}
                alt={`Groupe ${group.name}`}
                className="bounce-in"
                style={{
//flex: 0,
                  // margin: 5,
                  borderRadius: 16,
                  // minWidth: 100,
                  // minHeight: 100,
                  // maxWidth: 250,
                  // width: 250,
                  // height: 250,
                  width: "100%",
                  height: "100%",
                  // flex: "0 1",
                  // border: "1px dashed red",
                }}
              />

      } */}

        {false && percentageVotes > 0 && 
          <div style={{justifySelf: "flex-end", fontSize: "1.6em", fontWeight: "bold"}}>{percentageVotes} %</div>
        }

        <UserAvatar
          key={user.id}
          avatarId={user.avatarId}
          name={user.groupId ? group?.name : user.name}
group={group}
          //hasFocus={user.id === votee?.id}
          style={{height: "100%", justifyContent: "flex-end"}}
        >

    {/* <div style={{
      background: "coral",
display: "flex",
flexDirection: "column-reverse",
        // TODO PRECALC FRACTION (NO LONGER USES PERCENTAGE)
        flex: `${votometerBarHeightFraction}`,
//flex: 1,
//?        transition: "10s flex",
        transition: "10s all",
        //transition: "flex 2.5s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s",
    }}> */}
      {/* <div style={{
        display: "flex",
        flexDirection: "column-reverse",
        height: "100%",
      }}> */}
        {quiz.questions.map((question, i) => {
// TODO NO NEED TO CALC countVotes(question, i, user.id) AGAIN
// TODO AS calcVotometerHeight(userId) -> calcTotalVotes(userId)
// TODO ALREADY DOES FOR ALL QUESTIONS THIS SO COULD POSSIBLY CACHE RESULT.....?

// TODO ******************* NOT TAKING GROUPS INTO ACCOUNT !!! *******************
///
let totalVotesForAllQuestions = 0;
quiz.questions.map((question, i) => {
  const {totalVotes: totalVotesForQuestion, maxVotes: maxVotesForQuestion} = countVotes(question, i, user.id);
  totalVotesForAllQuestions += totalVotesForQuestion;
});
///
          const {totalVotes: totalVotesForQuestion, maxVotes: maxVotesForQuestion} = countVotes(question, i, user.id);
//TODO?
//? maxVotesForQuestion => maxTotalVotesForQuestion
          // TODO DRY
          //??? const votometerSegmentHeight = votometerBarMaxHeight * (totalVotesForQuestion / maxTotalVotes);
          // Calculate percentage of parent height to fill in proportion to the votes given to
          // this question and the total votes given to all questions. So if only this question
          // has received votes, it will fill 100% of the parent's height. Note it is the ancestor
          // element that fixes the height determined by the calculated votometerBarHeightFraction.
//!const votometerSegmentHeight = totalVotesForQuestion ? (totalVotesForQuestion / totalVotes) : 0;
//const barPortion = maxVotesForQuestion / totalVotesForQuestion;
const votometerSegmentHeight = totalVotesForAllQuestions ? (totalVotesForQuestion / totalVotesForAllQuestions) : 0;

//const lag = totalVotesForQuestion && totalVotesForQuestion === totalVotes ? 0 : 10;
const lag = 2;
//          const votometerSegmentHeight = totalVotesForQuestion / totalVotes;
          const icon = getIcon(question.iconType);
          const colour = "%23" + (icon.saturatedColour ?? icon.fadedColour).substring(1); // Url encode # symbol
          return (
<>
{/* <p>tot={totalVotesForQuestion} max={maxVotesForQuestion} totAllQu={totalVotesForAllQuestions} maxForUser={maxVotesForQuestion_forThisUser}</p>
<p>h={votometerSegmentHeight}</p>
<p>hf={votometerBarHeightFraction}</p> */}
            <div style={{
                width: "16px",
                // height: `${votometerSegmentHeight}%`,
//!                flex: `${votometerSegmentHeight}`,
//flex: `${votometerSegmentHeight ? (votometerBarHeightFraction / votometerSegmentHeight) : 0}`,
flex: `${votometerSegmentHeight ? (votometerBarHeightFraction * votometerSegmentHeight) : 0}`,
//?                transition: "flex 10s",
// TODO IF THIS IS THE ONLY USER TO VOTE THEN MAKE THIS TRANSITION INSTANTANEOUS
// TODO OTHERWISE THERE IS A SLIGHT GAP CAUSED BY THE LAG TO FILL THE BACKGROUND (WHICH IS NEEDED TO ANIMATED THE BAR'S DIFFERENT VOTE ICON SEGMENTS)
                transition: `${lag}s flex`,
//                transition: "flex .5s",
//                transition: `flex ${lag}s cubic-bezier(0.24, -0.31, 0.84, 1.35) 0s`,
                //transition: `flex ${lag}s cubic-bezier(.47, 1.64, .41, .8) 0s`,
                background: icon.colour,
                backgroundImage: `url('data:image/svg+xml;charset=UTF-8,${inlineSvg({iconType: question.iconType, colour, size: 16})}')`,
                backgroundPosition: "top",
                boxShadow: "0px -1px 3px #222",
              }}
            />
</>
          );
        })}
      {/* </div> */}
    {/* </div> */}


{false &&
<div className="Xflex-1" style={{position: 'relative', minWidth: '150px'}}>

{/* TEMP!!! */}
{/* TODO DRY */}
<div className="bar">
{quiz.questions.map((question, i) => {
  const {totalVotes: totalVotesForQuestion, maxVotes: maxVotesForQuestion} = countVotes(question, i, user.id);
  return (
    <div className="segment" style={{'--percent': totalVotesForQuestion / maxTotalVotes * 100, '--fill-color': getIcon(question.iconType).colour} as any}>
      <div className="fill">
        {/* <span style={{zIndex: 100}}>{totalVotesForQuestion / maxVotesForQuestion * 100}<sub> %</sub></span> */}
      </div>
    </div>
  );
})}

  <div className="base">
    <div className="text">{maxTotalVotes / totalVotes * 100}<sub> %</sub></div>
    <div className="icon"><i className="fa-duotone fa-chart-pie-simple"></i></div>
  </div>
</div>

</div>
}
</UserAvatar>

{true &&
  <div className="base">
        {true &&
            <div
              className="percentage-votes"
              style={{
                fontSize: '.4em',
                marginLeft: 10,
                position: 'relative',
                top: -6,
              }}
            >
              <CountdownCircleTimer
                key={percentageVotes}
                isPlaying={false}
                duration={100}
                initialRemainingTime={100 - percentageVotes}
                colors="#000000"
                size={80}
                strokeLinecap="square"
                strokeWidth={8}
              >{
                ({ remainingTime, elapsedTime }) => {
                  return (percentageVotes > 0 &&
                    <div className="timer" style={{fontSize: '1.2rem'}}>
                      <div className="value">{100 - remainingTime} %</div>
                    </div>
                  );
                }
              }
              </CountdownCircleTimer>
            </div>
          }
  </div>
}

</div>)
})}

</div>
  );
};
