//! import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group, GroupsState, User } from '../app/utilities/types';

const initialState: GroupsState = {
  groups: [],
  loading: false,
  error: null,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups: (state, { payload }: PayloadAction<Group[]>) => {
      state.groups = payload;
      console.log(`setGroups: payload=${JSON.stringify(payload)}`);
    },
    addGroup: (state, { payload }: PayloadAction<Group>) => {
      console.log(`addGroup: payload=${JSON.stringify(payload)}`);
      state.groups.push(payload);
    },
    joinGroup: (state, { payload }: PayloadAction<string>) => {
    },
    leaveGroup: (state) => {
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingComplete: (state) => {
      state.loading = false;
    },
  },
})

export const {
  setGroups,
  addGroup,
  joinGroup,
  leaveGroup,
  setLoading,
  setLoadingComplete,
} = groupsSlice.actions;

export default groupsSlice.reducer;

// Action
//! NOW SEND FROM THE SERVER AFTER 'JOIN ROOM' SOCKET EVENT
/*
export function getGroups(roomId: string) {
  debug(`Ìn getGroups: roomId=${roomId}`);
  return async (dispatch: Dispatch, getState: () => {}) => {
    dispatch(setLoading());
    try {
      debug(`getGroups: calling endpoint '/api/${roomId}/groups'`);
      const { data } = await axios(`/api/${roomId}/groups`);
      dispatch(setGroups(data.groups));
    } catch (e) {
      // Not handling errors
      console.log(e);
    } finally {
      dispatch(setLoadingComplete());
    }
  }
}
*/

//! NOT USED
export function selectPopulatedGroups(groups: Group[], allPlayers: User[]) {
  return groups.filter(
    group => allPlayers.some(user => user.groupId === group.id)
  );
}

const groupsMembersByIndex = new Map<number, User[]>();

// TODO THIS COULD ALSO BE SHARED BY THE EntitiesInfo COMPONENT
// TODO access group etc directly from above group slice state etc instead of passing it in
// TODO ALSO MEMOIZE (AND ADD AS AUTO COMPUTED PROPERTY TO STATE)
export function selectGroupMembers(
  allPlayers: User[],
  groups: Group[],
  groupId: string|undefined,
  userId: string|undefined,
  showAll: boolean,
): [Group, User[]][] {
  // Remove all group users except the first who is its representative
  // so only show the info for each group once!
  // Note solo users are placed into a default group.
  //! const groupsMembersByIndex = new Map<number, User[]>();

  // const representativeUsers = allPlayers
  //   // Ensure most recent users appear first
  //   .reverse()
  //   .filter(user => {
  //     // Only include group and members or solo user if not an admin
  //     if (!showAll && (user.groupId !== groupId || (!user.groupId && user.id !== userId))) {
  //       return false;
  //     }

  //     // Ensures default group will be found when the group id is not defined
  //     const userGroupId = user.groupId ?? "";
  //     const groupIndex = groups.findIndex(group => group.id === userGroupId);
  //     const members = groupsMembersByIndex.get(groupIndex);
  //     if (!members) {
  //       groupsMembersByIndex.set(groupIndex, [user]);
  //       return true;
  //     }
  //     members.push(user);
  //     return false;
  //   });
  selectRepresentativePlayers(
    allPlayers,
    groups,
    groupId,
    userId,
    showAll,
  );

  const groupsMembers: [Group, User[]][] = Array.from(groupsMembersByIndex)
    .sort(([group1Index], [group2Index]) => {
      // Ensure default group with index 0 will be positioned last
      return (group1Index || Infinity) - (group2Index || Infinity);
    })
    .map(([groupIndex, users]) => [groups[groupIndex], users]);

  return groupsMembers;
}

// TODO MAYBE JUST CALL "selectPlayers" FOR SIMPLICITY?
// TODO SHOULD PROBABLY GO IN users.slice, ARRRGGGHHH!!!
export function selectRepresentativePlayers(
  allPlayers: User[],
  groups: Group[],
  groupId?: string|undefined,
  userId?: string|undefined,
  showAll: boolean = true,
): User[] {
  // Remove all group users except the first who is its representative
  // so only show the info for each group once!
  // Note solo users are placed into a default group.
  // TODO NOW USES MODULE GLOBAL HERE
  //! const groupsMembersByIndex = new Map<number, User[]>();
  groupsMembersByIndex.clear();

  const representativePlayers = allPlayers
    // Ensure most recent users appear first
//TODO IN FACT THIS MESSES UP THE LOGIC ELSEWHERE THAT DEPENDS ON THE FIRST MEMBER OF THE TEAM ALWAYS BEING ITS REPRESENTATIVE!
//TODO ALTHOUGH FOR THE ADMIN DISPLAY OF PLAYER NEEDS TO REVERSE THE DISPLAY ORDER SO NEW PLAYERS JOINING ARE ALWAYS SHOW FIRST (I.E. DESCENDING CHRONOLOGICAL ORDER)
//!    .reverse() //! <---------------------- PROB NOT APPROPRIATE FOR PLAYERS' VOTING ORDER!
    .filter(user => {
      // Only include group and members or solo user if not an admin
      if (!showAll && (user.groupId !== groupId || (!user.groupId && user.id !== userId))) {
        return false;
      }

      // Ensures default group will be found when the group id is not defined
      const userGroupId = user.groupId ?? "";
      const groupIndex = groups.findIndex(group => group.id === userGroupId);
      const members = groupsMembersByIndex.get(groupIndex);
      if (!members) {
        groupsMembersByIndex.set(groupIndex, [user]);
        return true;
      }
      members.push(user);
// Always include all solo players
if (!userGroupId) return true;
      return false;
    });

  return representativePlayers;
}
